export const entityBadgeStatus = (entityType: string) => {
  const colours: any = {
    Individual: 'inprogress',
    Company: 'rejected',
    Trust: 'resolved',
    PartnerShip: 'pending',
  };

  return colours[entityType] || 'default';
};

export const getClientName = (client: any) => {
  if (!client?.__typename) return '';
  return client.__typename === 'Person'
    ? `${client.firstName} ${client.lastName}`
    : client.registeredName ?? client.sortName;
};
