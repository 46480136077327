/** @jsx jsx */

import { useEffect, useState } from 'react';
import { components as reactSelectComponents } from 'react-select';
import Select, { Props } from 'react-select/base';

import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { SearchIcon } from '@reckon-web/icon/icons/SearchIcon';
import { XIcon } from '@reckon-web/icon/icons/XIcon';
import { LoadingDots } from '@reckon-web/loading';
import { useTheme } from '@reckon-web/theme';

import { defaultOptionRenderer } from './option-renderers';
import { AutocompleteProps, BaseOption } from './types';

function useHasValueBeenConstantForMs(value: any, ms: number) {
  const [hasValueBeenConstant, setHasValueBeenConstant] = useState(false);

  useEffect(() => {
    setHasValueBeenConstant(false);
    const id = setTimeout(() => setHasValueBeenConstant(true), ms);
    return () => clearTimeout(id);
  }, [value, ms]);

  return hasValueBeenConstant;
}

type AutocompleteState =
  | { type: 'no-value' }
  | { type: 'loading' }
  | { type: 'loaded'; options: BaseOption[] };

function useAutocompleteState(
  inputValue: string,
  loadOptions: (inputValue: string) => Promise<any[]>
): AutocompleteState {
  const [optionsState, setOptionsState] = useState<{
    loadOptions: any;
    inputValue: string;
    options: BaseOption[];
  }>({ options: [], loadOptions: null, inputValue: '' });
  const isValueStable = useHasValueBeenConstantForMs(inputValue, 200);

  useEffect(() => {
    let shouldSet = true;
    if (isValueStable && inputValue) {
      // TODO: what should we do if there is an error?
      loadOptions(inputValue).then((options) => {
        if (shouldSet) {
          setOptionsState({ options, loadOptions, inputValue });
        }
      });
    }
    return () => {
      shouldSet = false;
    };
  }, [loadOptions, isValueStable, inputValue]);

  if (inputValue === '') {
    return {
      type: 'no-value',
    };
  }

  if (
    (optionsState.inputValue !== inputValue ||
      optionsState.loadOptions !== loadOptions) &&
    isValueStable
  ) {
    return {
      type: 'loading',
    };
  }

  return {
    type: 'loaded',
    options: optionsState.options,
  };
}

const components: Props['components'] = {
  LoadingIndicator() {
    return null;
  },
  DropdownIndicator() {
    return null;
  },
  IndicatorSeparator() {
    return null;
  },
  ClearIndicator(props) {
    return (
      <reactSelectComponents.ClearIndicator {...props}>
        <XIcon size="small" />
      </reactSelectComponents.ClearIndicator>
    );
  },
  Input(props) {
    return (
      <reactSelectComponents.Input
        {...props}
        aria-describedby={(props as any).selectProps['aria-describedby']}
      />
    );
  },
  LoadingMessage() {
    return (
      <Flex justifyContent="center" marginY="xlarge">
        <LoadingDots label="Loading options" />
      </Flex>
    );
  },
  ValueContainer(props) {
    return (
      <reactSelectComponents.ValueContainer {...props}>
        <span
          css={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: 40,
          }}
        >
          <SearchIcon color="dim" size="small" />
        </span>
        {props.children}
      </reactSelectComponents.ValueContainer>
    );
  },
};

export function Autocomplete<Option extends BaseOption>(
  props: AutocompleteProps<Option>
) {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const state = useAutocompleteState(inputValue, props.loadOptions);

  return (
    <Select
      aria-describedby={props['aria-describedby']}
      autoFocus={props.autoFocus}
      components={components}
      filterOption={() => true}
      formatOptionLabel={props.renderOption as any}
      inputId={props.id}
      inputValue={inputValue}
      isClearable
      isLoading={state.type === 'loading'}
      menuIsOpen={isOpen && state.type !== 'no-value'}
      menuPortalTarget={typeof document !== 'undefined' ? document.body : null}
      menuPosition="fixed"
      noOptionsMessage={props.noOptionsMessage}
      onChange={(option: any) => props.onChange(option)}
      onInputChange={(value) => setInputValue(value)}
      onMenuClose={() => setIsOpen(false)}
      onMenuOpen={() => setIsOpen(true)}
      options={state.type === 'loaded' ? state.options : []}
      placeholder={props.placeholder ?? 'Search...'}
      styles={useStyles(props)}
      value={props.value}
    />
  );
}

const defaultNoOptionsMessage = () => 'No matching results.';
Autocomplete.defaultProps = {
  renderOption: defaultOptionRenderer,
  noOptionsMessage: defaultNoOptionsMessage,
};

// Styles
// ------------------------------

function useStyles(props: AutocompleteProps<any>): Props['styles'] {
  const { elevation, palette, shadow } = useTheme();

  return {
    // CONTROL
    control: (base, state) => ({
      ...base,
      backgroundColor: props.invalid
        ? palette.formInput.backgroundInvalid
        : palette.formInput.background,
      borderRadius: 8,
      borderColor: state.isFocused
        ? palette.formInput.borderFocused
        : 'transparent',
      boxShadow: state.isFocused
        ? `0 0 0 2px ${palette.global.focusRing}`
        : undefined,
      color: props.invalid
        ? palette.formInput.textInvalid
        : palette.formInput.text,
      minHeight: 40,
      transition: undefined,

      ':hover': {
        borderColor: state.isFocused ? undefined : palette.formInput.border,
      },
    }),
    clearIndicator: (base) => ({ ...base, padding: 11 }),
    valueContainer: (base) => ({
      ...base,
      position: 'relative',
      padding: '2px 0',
    }),
    input: (base) => ({
      ...base,
      color: props.invalid
        ? palette.formInput.textInvalid
        : palette.formInput.text,
      margin: 0,
      padding: 0,
    }),
    singleValue: (base) => ({ ...base, left: 40, margin: 0 }),
    placeholder: (base) => ({
      ...base,
      color: palette.formInput.textPlaceholder,
      left: 40,
      margin: 0,
    }),

    // MENU
    noOptionsMessage: (base) => ({
      ...base,
      fontSize: 14,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: palette.background.dialog,
      borderRadius: 8,
      boxShadow: shadow.medium,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: elevation.toast,
    }),
    menuList: (base) => ({
      ...base,
      paddingBottom: 8,
      paddingTop: 8,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused
        ? palette.menuItem.backgroundFocused
        : undefined,
      color: state.isFocused
        ? palette.menuItem.textFocused
        : palette.menuItem.text,

      ':active': {
        backgroundColor: palette.menuItem.backgroundPressed,
        color: palette.menuItem.textPressed,
      },
    }),
  };
}
