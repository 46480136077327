/** @jsx jsx */

import { FormatOptionLabelMeta } from 'react-select/base';

import { EntityAvatar, UserAvatar } from '@reckon-web/avatar';
import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { Text } from '@reckon-web/text';

import { BaseOption, EntityDetailOption } from './types';

export function defaultOptionRenderer(
  option: BaseOption,
  { context }: FormatOptionLabelMeta<BaseOption>
) {
  return context === 'value' ? (
    option.label
  ) : (
    <Text color="inherit" size="small" weight="medium">
      {option.label}
    </Text>
  );
}

export function userOptionRenderer(
  option: BaseOption,
  { context }: FormatOptionLabelMeta<BaseOption>
) {
  return context === 'value' ? (
    option.label
  ) : (
    <Flex alignItems="center" gap="medium">
      <UserAvatar size="xsmall" name={option.label} />
      <Text color="inherit" size="small" weight="medium">
        {option.label}
      </Text>
    </Flex>
  );
}

export function entityOptionRenderer(
  option: BaseOption,
  { context }: FormatOptionLabelMeta<BaseOption>
) {
  return context === 'value' ? (
    option.label
  ) : (
    <Flex alignItems="center" gap="medium">
      <EntityAvatar
        // the type doesn't matter when it's xsmall
        type="Person"
        size="xsmall"
        name={option.label}
      />
      <Text color="inherit" size="small" weight="medium">
        {option.label}
      </Text>
    </Flex>
  );
}

export function entityDetailOptionRenderer(
  option: EntityDetailOption,
  { context }: FormatOptionLabelMeta<EntityDetailOption>
) {
  return context === 'value' ? (
    option.label
  ) : (
    <Flex alignItems="center" gap="medium">
      <EntityAvatar type={option.type} size="small" name={option.label} />
      <Flex flexGrow={1} direction="column">
        <Text color="inherit" size="small" weight="medium">
          {option.label}
        </Text>
        <Text
          color="inherit"
          size="xsmall"
          leading="tight"
          css={{ opacity: 0.8 }}
        >
          {option.email} · {option.phone}
        </Text>
      </Flex>
    </Flex>
  );
}
