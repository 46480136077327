import React from 'react';

import { Field } from '@reckon-web/field';

import { Autocomplete } from './Autocomplete';
import { AutocompleteFieldProps, BaseOption } from './types';

export const AutocompleteField = <Option extends BaseOption>({
  description,
  helpText,
  id,
  invalidMessage,
  label,
  labelVisible,
  reserveMessageSpace,
  ...props
}: AutocompleteFieldProps<Option>) => {
  return (
    <Field
      description={description}
      helpText={helpText}
      id={id}
      invalidMessage={invalidMessage}
      label={label}
      labelVisible={labelVisible}
      reserveMessageSpace={reserveMessageSpace}
    >
      {({ inputProps }) => {
        return <Autocomplete {...props} {...inputProps} />;
      }}
    </Field>
  );
};
